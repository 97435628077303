import React, { useState, useEffect, useRef } from 'react';
import ChatDisplay from '../../components/chatDisplay';
import ChatInput from '../../components/chatInput';
import Nav from '../../components/nav';
import SideMenu from '../../components/sideMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faRepeat, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'
import ChatResponse from '../../components/chatResponse'

const Home = () => {

    let checkbox= true;


    const [text, setText] = useState(null);
    
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    console.log(chatHistory)
    const [streamedResponse, setStreamedResponse] = useState("");

    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
      // Load chat history from localStorage on component mount
      const storedChatHistory = localStorage.getItem('chatHistory');
      if (storedChatHistory) {
        setChatHistory(JSON.parse(storedChatHistory));
      }

      
    }, []);

    const updateChatHistory = (role, content) => {
      setChatHistory(prevChatHistory => {
        const updatedHistory = [...prevChatHistory, { role, content }];
        localStorage.setItem('chatHistory', JSON.stringify(updatedHistory));
        return updatedHistory;
      });
    };


    const refreshChatHistory = ()=>{
      setChatHistory([])
      localStorage.removeItem('chatHistory')
    }
    
    
    const requestData = async () => {
      
      if(text===null || (text.trim())===""){
        return console.log("Input text is empty. Please input some text to start conversation.")
      }
      
      updateChatHistory("user", text);
      
      const data = {
        text,
        chat_history: chatHistory, // You might want to send the entire chat history to the server
        stream: true
      };

      setText("");

      console.log("clicked...")
    
      try {
        setIsLoading(true)
        setIsProcessing(true)
        const response = await fetch('https://dax-api.darkroast.co/v2/chatbot/dax/chat', {
          method: 'POST',
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
    
        const reader = response.body.getReader();
    
        let res = '';
        setStreamedResponse('')
        
        
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          const chunk = new TextDecoder().decode(value);
          res += chunk;
          setStreamedResponse(prevResponse => prevResponse + chunk);
          setIsLoading(false)
        }
    
        // Append the assistant's response to the chat history without modifying user messages
        updateChatHistory("assistant", res);
        
        // Clear the input text after receiving the response
        setText("");
        setIsLoading(false)
        setIsProcessing(false)
    
      } catch (error) {
        setError(error);
        setText("");
        setIsLoading(false)
        setIsProcessing(false)
        updateChatHistory("assistant", "Failed to generate your response.");
      }
    };


    const chatRef = useRef(null)

    useEffect(() => {
      // Load chat history from localStorage on component mount
      if(chatRef.current){
        //chatRef.current.scrollIntoView()
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }

      
    }, [chatHistory,streamedResponse]);

    


  
  return (
    <div className='home'>
        <Nav />
        <input type="checkbox" defaultChecked={true} id="menu-toggle" className="menu-toggle-checkbox" />
        <label htmlFor="menu-toggle" className="menu-toggle-label">
            <FontAwesomeIcon icon={faBars} className="fa-bars menu-icon" />
            <FontAwesomeIcon icon={faTimes} className="fa-times menu-icon" />
        </label>

        <div className='home-content'>
            <div className='refresh-btn' onClick={refreshChatHistory}>
              <FontAwesomeIcon icon={faRepeat} size="1x" />
            </div>
            
            <SideMenu/>
            <div className='home-right-content'>
                <div className='chat-display'  ref={chatRef}>
                  <ChatResponse loading={isLoading}  data={chatHistory} response={streamedResponse}/>
                  <div></div>
                </div>
                <ChatInput 
                  text={text}
                  setText={setText}
                  onClick={requestData}
                  isProcessing={isProcessing}
                />
            </div>
        </div>
        </div>
  );
};

export default Home;
