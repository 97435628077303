import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const SideMenu = () => {
  return (
    <div className='side-menu light-green'>
        <div>
            <h2 className='light-green'>
                Hello, I'm <span className='dark-green'>Dax</span>
            </h2>
            <p>
                I'm your trusty new copywriting & research assistant. I'm trained to write any content you need for a CPG & DTC brand/product. Such as:
            </p>
            <ul style={{ marginTop: '20px' }}>
                <li style={{ fontWeight: 'bold' }}>Social Media Posts</li>
                <li style={{ fontWeight: 'bold' }}>Advertising Copy</li>
                <li style={{ fontWeight: 'bold' }}>Product Descriptions</li>
                <li style={{ fontWeight: 'bold' }}>Website Copy</li>
                <li style={{ fontWeight: 'bold' }}>Blog Posts</li>
                <li style={{ fontWeight: 'bold' }}>Recipe Ideas (This one's my favorite)</li>
                <li style={{ fontWeight: 'bold' }}>Email Copy</li>
                <li style={{ fontWeight: 'bold' }}>SEO Content</li>
            </ul>
            <p>
                To get started, tell me the type of content you'd like to write and provide either a link or a description of the product/brand. Here are some examples of things you can ask me:
            </p>
            <div style={{ marginTop: '20px' }}>
                <div style={{ marginTop: '10px', fontStyle:'italic', fontWeight: 'bold' }}>"Create a Facebook post to announce the launch of this product: brandwebsite.com/product-page"</div>
                <div style={{ marginTop: '10px', fontStyle:'italic', fontWeight: 'bold' }}>"Write three new catchy tag lines for gut-friendly keto energy bites called DAX SNAX."</div>
                <div style={{ marginTop: '10px', fontStyle:'italic', fontWeight: 'bold' }}>"Give me three healthy recipe ideas for this product brandwebsite.com/product-page"</div>
            </div>
            <p style={{ marginTop: '20px' }}>
                I can even help with research. You can also ask me things like:
            </p>
            <div style={{ marginTop: '20px' }}>
                <div style={{ marginTop: '10px', fontStyle:'italic', fontWeight: 'bold' }}>"How do I reduce COGS for this product brandwebsite.com/product-page"</div>
                <div style={{ marginTop: '10px', fontStyle:'italic', fontWeight: 'bold' }}>"Give me 5 SEO keyword ideas for this website brandwebsite.com"</div>
                <div style={{ marginTop: '10px', fontStyle:'italic', fontWeight: 'bold' }}>"Name the top ten competitors for gut-friendly keto energy bites called DAX SNAX."</div>
            </div>
            <p style={{ marginTop: '40px' }}>
                Now, let's get started!
            </p>
            <p>
                P.S. If you're not thrilled with my answers, please ask me to "try again." I don't mind :)
            </p>
        </div>
    </div> 
  )
}

export default SideMenu