import React from 'react'

import logo from "../images/DAX_Logo.svg"

const Nav = () => {
  return (
    <div className="navbar">
       <div className='nav-left'>
            <div className='nav-logo-div'>
                <img src={logo} alt="logo" className='nav-logo' />
                {/* <div className='nav-logo'>DAX</div> */}
                {/* <div className='logo-txt'>by Darkroast</div> */}
            </div>
            <div className='nav-title'>Copywriting Assistant for CPG & DTC</div>
       </div>

       <div className='nav-right'>
            <div className='nav-user'>TP</div>
            <button className='nav-signout-btn'>Sign Out</button>
       </div>

    </div>
  )
}

export default Nav