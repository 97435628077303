import React, { useRef, useEffect } from 'react';
import { faCog, faRepeat, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import Loading from './loading';
import ReactMarkdown from 'react-markdown';

import DaxLogo from "../images/daxicon.png";

const ChatResponse = ({ loading, data, response }) => {
  


  const [streamedResponse, setStreamedResponse] = React.useState("");
  const [historyData, setHistoryData] = React.useState("");

  React.useEffect(() => {
    setStreamedResponse(response);
  }, [response]);

  React.useEffect(() => {
    setStreamedResponse("")
    setHistoryData(data);
  }, [data]);


  const copyText = (txt) => {
    navigator.clipboard.writeText(txt)
      .then(() => {
        console.log('Copied text:', txt);
      })
      .catch((error) => {
        console.error('Failed to copy text: ', error);
      });
  };
  

  return (
    <div className='chat-response'>
      {historyData && historyData.map(itm => (itm.role === "user" ? (<div className='chat-txt'>
        <div className='user-icon'><FontAwesomeIcon icon={faUser} size="1x" /></div>
        <ReactMarkdown>{itm.content}</ReactMarkdown>
      </div>) : (itm.content === "Failed to generate your response.") ? <div className='chat-res-info'>
        <div className='assistant-icon'><img src={DaxLogo} alt="logo" className='response-icon' /></div>
        <ReactMarkdown className='error'>{itm.content}</ReactMarkdown>
      </div> : <div className='chat-res-info'>
        <div className='assistant-icon'><img src={DaxLogo} alt="logo" className='response-icon' /></div>
        <ReactMarkdown>{itm.content}</ReactMarkdown>
        <div className='chat-res-icons'>
          <FontAwesomeIcon icon={faNewspaper} size="1x" onClick={()=>copyText(itm.content)}/>
          {/* <FontAwesomeIcon icon={faThumbsUp} size="1x" />
          <FontAwesomeIcon icon={faThumbsDown} size="1x" />
          <FontAwesomeIcon icon={faRepeat} size="1x" /> */}
        </div>
      </div>))}

      {loading ? <div className='chat-res-info'>
        <div className='assistant-icon'><img src={DaxLogo} alt="logo" className='response-icon' /></div>
        <Loading />
      </div> : (response && streamedResponse !== "") && <div className='chat-res-info'>
        <div className='assistant-icon'><img src={DaxLogo} alt="logo" className='response-icon' /></div>
        <ReactMarkdown>{streamedResponse}</ReactMarkdown>
        <div className='chat-res-icons'>
          <FontAwesomeIcon icon={faNewspaper} size="1x" onClick={()=>copyText(streamedResponse)} />
          {/* <FontAwesomeIcon icon={faThumbsUp} size="1x" />
          <FontAwesomeIcon icon={faThumbsDown} size="1x" />
          <FontAwesomeIcon icon={faRepeat} size="1x" /> */}
        </div>
      </div>}
      
    </div>
  )
}

export default ChatResponse;
