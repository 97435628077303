import React from 'react'
import Loader from "react-js-loader";

function Loading({txt}) {
  return (
    <div className='loading-div' >
        <Loader type="bubble-top" bgColor='black' color='green' title={txt?txt:""} size={20} />
    </div>
  )
}

export default Loading