import { faArrowRight, faInfo, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const ChatInput = ({ isProcessing, text, setText, onClick }) => {
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [inputRows, setInputRows] = React.useState(1);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey && !isProcessing) {
            e.preventDefault(); 
            onClick(); 
        }
    };
    
    const handleInputChange = (e) => {
        setText(e.target.value);
        if(e.target.value.split('\n').length<=10){
            setInputRows(e.target.value.split('\n').length);
        }else{
            setInputRows(10)
        }
    };

    
    const handlePaste = (e) => {
        setTimeout(() => {
            const textarea = e.target;
            const inputDiv = document.getElementById('input-div');
            const maxRows = 10;
            const rowsToAdd = textarea.value.split('\n').slice(inputRows - 1, maxRows);

            if (inputRows < maxRows) {
                const scrollHeight = textarea.scrollHeight;
                const newHeight = Math.min(scrollHeight, maxRows * 20);
                textarea.style.height = `${newHeight}px`;
                inputDiv.style.height = `${newHeight + 20}px`;
                const marginTop = -(newHeight - 20);
                inputDiv.style.marginTop = `${marginTop}px`;
            }

            setInputRows(prevRows => Math.min(prevRows + rowsToAdd.length, maxRows));
        }, 0);
    };



    const [placeholder, setPlaceholder] = React.useState('What can I create for you?');

    // React.useEffect(() => {
    //     const screenWidth = window.innerWidth;
    //     let newPlaceholder = '';

    //     if (screenWidth < 780) {
    //         newPlaceholder = 'Message';
    //     } else {
    //         newPlaceholder = 'What can I create for you?';
    //     }

    //     setPlaceholder(newPlaceholder);

    //     // Update placeholder if window is resized
    //     const handleResize = () => {
    //         const newScreenWidth = window.innerWidth;
    //         let newPlaceholder = '';

    //         if (newScreenWidth <= 780) {
    //             newPlaceholder = 'Message';
    //         } else {
    //             newPlaceholder = 'What can I create for you?';
    //         }

    //         setPlaceholder(newPlaceholder);
    //     };

    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //     window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    


    React.useEffect(() => {

        if(inputRows<10){
            const textarea = document.querySelector('textarea');
            const inputDiv = document.getElementById('input-div');

            textarea.style.height = 'auto';

            const scrollHeight = textarea.scrollHeight;

            textarea.style.height = `${scrollHeight}px`;

            
            inputDiv.style.height = `${scrollHeight + 20}px`;

            
            const marginTop = -(scrollHeight-20);
            inputDiv.style.marginTop = `${marginTop}px`;
        }
    }, [text, inputRows]);

    React.useEffect(() => {
            const screenWidth = window.innerWidth;
    
            if (screenWidth < 780) {
                const textarea = document.querySelector('textarea');
                const inputDiv = document.getElementById('input-div');
                const generateBtnS = document.getElementById('generate-btn-s');

                textarea.style.height = 'auto';

                const scrollHeight = textarea.scrollHeight;
                console.log(scrollHeight)

                textarea.style.height = `50px`;

                inputDiv.style.height = `100px`;
                const marginTop = -(scrollHeight+20);

                generateBtnS.style.bottom = `32px !important`;
                inputDiv.style.marginTop = `${marginTop}px`;
            }
    }, []);




    
    React.useEffect(() => {
        const handleResize = () => {
            const generateBtn = document.getElementById('generate-btn');
            const generateBtnS = document.getElementById('generate-btn-s');
            const textarea = document.querySelector('textarea');
            const scrollHeight = textarea.scrollHeight;
            const screenWidth = window.innerWidth;

                if (screenWidth <= 780 && screenWidth > 391) {
                    generateBtnS.style.bottom = `32px !important`;
                } else {
                    generateBtn.style.bottom = '16px !important';
                }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);




    return (
        <div className='chat-input-div' id='input-div'>
            {selectedFile && <div className='chat-file-select'>{selectedFile.name}</div>}
            <div className='info-btn' style={{display:'none'}}>
                <FontAwesomeIcon icon={faInfo} size="x" className='info-icon' />
            </div>
            <div className="file-input file-input-div">
                <input type="file" id="fileInput" className="file" onChange={(e) => handleFileChange(e)} />
                <label htmlFor="fileInput">
                    <FontAwesomeIcon icon={faPaperclip} size="1x" />
                </label>
            </div>
            {/* Use textarea instead of input for multiline text */}
            <textarea
                rows={inputRows}
                value={text}
                onChange={(e) => handleInputChange(e)}
                onKeyDown={handleKeyDown} 
                onPaste={handlePaste} 
                className='txt-input'
                placeholder={placeholder}
            />
            <button className="generate-btn" id='generate-btn' disabled={isProcessing} onClick={() => onClick()}>Generate</button>
            {/* mobile screen */}
            <button className="generate-btn-s" id='generate-btn-s' disabled={isProcessing} onClick={() => { onClick() }}>
                <FontAwesomeIcon icon={faArrowRight} size="1x" />
            </button>
        </div>
    );
}

export default ChatInput;
